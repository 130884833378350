'use strict';

/**
* @fileOverview Основной файл инициализации модулей
*/

// ----------------------------------------
// Imports
// ----------------------------------------

import '#/_vendors/promise-polyfill';
import '#/_vendors/jquery';
import '#/_vendors/gsap/gsap';

import moduleLoader from '#/module-loader';
import wsTabs from '#/_modules/wstabs';
import cookieData from '#/_modules/cookie-data';
import BlockToggler from '#/_modules/blockToggler';

// ----------------------------------------
// Public
// ----------------------------------------

window.jQuery(document).ready($ => {
	moduleLoader.init($(document));
	cookieData.askUsage(); // если нужно использовать куки
	wsTabs.init();
	// wsTabs.setActive(); // если нужно принудительно активировать табы

	var win = $(window);
	var needMore = true;
	var loading = false;
	var cheker = true;

	function getElements () {
		if ($('.js-loading-items').height() - win.height() <= win.scrollTop()) {
			$('.preloader-2').show();

			console.log('load');

			loading = true;

			$.ajax({
				url: $('.js-loading-items').attr('data-ajax-items'),
				dataType: 'json',
				data: JSON.parse($('.js-loading-items').attr('data-params')),
				type: 'post',
				success: function (response) {
					$('.preloader-2').hide();

					// $('.js-loading-items').append(response);

					if (response.markup) {
						$('.js-loading-items').append(response.markup);
					}

					if (response.param) {
						$('.js-loading-items').attr('data-params', response.param);
					}

					if (response.newUrl) {
						$('.js-loading-items').attr('data-ajax-items', response.newUrl);
					}

					if (response.end) {
						needMore = false;
					}

					loading = false;

					console.log(response);
				}

			});
		}
	}

	getElements();

	win.scroll(function () {
		if (needMore && !loading && cheker) {
			getElements();
		}
	});

	const $brandsList = $('#js-brands-list');

	let chooseSelects = {
		city: '',
		brand: ''
	};

	let preloader = $('.preloader');

	function func1 (ajaxUrl) {
		$.ajax({
			url: ajaxUrl,
			type: 'post',
			dataType: 'json',
			data: chooseSelects,
			cache: false,
			beforeSend: function () {
				preloader.show(100);
			},
			success: function (response) {
				$brandsList.html(response.markup);
				$('.js-loading-items').attr('data-params', response.dataParams);
				preloader.hide(100);
				window.city.select2('destroy');
				window.city.html(response.options);
				window.city.select2({
					minimumResultsForSearch: 50
				});

				scrollToMap();
			}
		});
	}

	const $akciiList = $('#js-akcii');

	let chooseSelectsTwo = {
		brandAkcii: ''
	};

	/* TODO */

	function func2 (ajaxUrl) {
		$.ajax({
			url: ajaxUrl,
			type: 'post',
			dataType: 'json',
			data: chooseSelectsTwo,
			cache: false,
			beforeSend: function () {
				preloader.show(100);
			},
			success: function (response) {
				$akciiList.html(response.markup);
				$('.js-loading-items').attr('data-params', response.dataParams);
				preloader.hide(100);
			}
		});
	}

	const $learnList = $('#js-learning');

	let chooseSelectsThree = {
		brandLearn: '',
		cityLearn: ''
	};

	/* TODO */

	function func3 (ajaxUrl) {
		$.ajax({
			url: ajaxUrl,
			type: 'post',
			dataType: 'json',
			data: chooseSelectsThree,
			cache: false,
			beforeSend: function () {
				preloader.show(100);
			},
			success: function (response) {
				$learnList.html(response.markup);
				$('.js-loading-items').attr('data-params', response.dataParams);
				preloader.hide(100);
				window.selectCity.select2('destroy');
				window.selectCity.html(response.options);
				window.selectCity.select2({
					minimumResultsForSearch: 50
				});
			}
		});
	}

	if ($('.js-select').length) {
		require.ensure([], (require) => {
			require('select2');
			$('.js-select').each(function () {
				if ($(this).attr('data-ajax-select')) {
					$(this).select2({
						minimumResultsForSearch: 50
					});

					if ($(this).attr('data-ajax-select') === 'city') {
						window.city = $(this);
						$(this).on('select2:select', function (evt) {
							cheker = false;
							chooseSelects.city = evt.target.value;
							func1($(this).attr('data-ajaxx-url'));
						});

						chooseSelects.city = $(this).select2('val');
					}

					if ($(this).attr('data-ajax-select') === 'brand') {
						$(this).on('select2:select', function (evt) {
							cheker = false;
							chooseSelects.brand = evt.target.value;
							func1($(this).attr('data-ajaxx-url'));
						});

						chooseSelects.brand = $(this).select2('val');
					}

					if ($(this).attr('data-ajax-select') === 'brandAkcii') {
						$(this).on('select2:select', function (evt) {
							cheker = false;
							chooseSelectsTwo.brandAkcii = evt.target.value;
							func2($(this).attr('data-ajaxx-url'));
						});
						chooseSelectsTwo.brandAkcii = $(this).select2('val');
					}

					if ($(this).attr('data-ajax-select') === 'brandLearn') {
						$(this).on('select2:select', function (evt) {
							cheker = false;
							chooseSelectsThree.brandLearn = evt.target.value;
							func3($(this).attr('data-ajaxx-url'));
						});
						chooseSelectsThree.brandLearn = $(this).select2('val');
					}

					if ($(this).attr('data-ajax-select') === 'cityLearn') {
						window.selectCity = $(this);
						$(this).on('select2:select', function (evt) {
							cheker = false;
							chooseSelectsThree.cityLearn = evt.target.value;
							func3($(this).attr('data-ajaxx-url'));
						});
						chooseSelectsThree.cityLearn = $(this).select2('val');
					}
				} else {
					$(this).select2({
						minimumResultsForSearch: 50
					});
				}
			});
		}, 'select');
	}

	window.beautyForm = function () {
		$('.js-beauty-form input').each(function () {
			if (!$(this).hasClass('js-hover-inited')) {
				var inputsTextareas = $('.js-beauty-form input, .js-beauty-form textarea');

				inputsTextareas.on('focus', function () {
					var parent = $(this).closest('.form-group');

					parent.addClass('is-focused');
				});

				inputsTextareas.on('blur', function () {
					var parent = $(this).closest('.form-group');

					if (parent.find('input').length) {
						if (!parent.find('input').val().length) {
							parent.removeClass('is-focused');
						}
					}
					if (parent.find('textarea').length) {
						if (!parent.find('textarea').val().length) {
							parent.removeClass('is-focused');
						}
					}
				});

				$(this).addClass('js-hover-inited');
			}
		});
	};

	if ($('.js-beauty-form').length) {
		window.beautyForm();
	}

	if ($('.js-hovered-block').length) {
		var items = $('.js-hovered-item');

		items.hover(function (evt) {
			let dat = $(this);
			if (!dat.find('.is-active').length) {
				let parent = dat.parent();
				let datItems = parent.find('.js-hovered-item');

				dat.addClass('is-hovered');
				for (let i = 0; i < datItems.length; i++) {
					let datI = datItems.eq(i);
					if (!datI.hasClass('is-hovered')) {
						datI.addClass('is-unhovered');
					}
				}
			}
		}).on('mouseleave', function (evt) {
			let dat = $(this);
			if (!dat.find('.is-active').length) {
				let parent = dat.closest('.js-hovered-block');
				let datItems = parent.find('.js-hovered-item');

				for (let i = 0; i < datItems.length; i++) {
					let datI = datItems.eq(i);
					if (!datI.hasClass('is-hovered')) {
						datI.removeClass('is-unhovered');
					} else {
						datI.removeClass('is-hovered');
					}
				}
			}
		});
	}

	window.manager = {};

	window.manager.newInfo = new BlockToggler({

		el: $('.js-show-block'),
		button: $('.js-btn-show'),
		closeButton: $('.js-btn-hide'),
		block: $('.js-block-open'),
		closeOnScroll: false,

		beforeOpen: function () {

		},
		afterOpen: function () {

		},
		beforeClose: function () {

		},
		afterClose: function () {

		}

	});

	if ($('.js-swiper-slider').length) {
		import('./_modules/sliders').then((module) => {
		const Slider = module.slider;
		let sliders = [];

		$('.js-swiper-slider').each(function (index) {
			let newSlider = null;
			var _this = $(this);

			if (_this.hasClass('js-main-slider')) {
				newSlider = new Slider({
					item: _this,
					arrows: true,
					resizeHalper: true,
					swiperConfig: {
						speed: 800,
						effect: 'fade',
						crossFade: true,
						loop: true,
						slidesPerView: 1
					}
				});

				setTimeout(function () {
					newSlider.slider.update();
				}, 300);
			}

			if (_this.hasClass('js-akcii-slider')) {
				newSlider = new Slider({
					item: _this,
					arrows: false,
					swiperConfig: {
						speed: 800,
						loop: true,
						slidesPerView: 1,
						autoplay: {
							delay: 3000
						}
					}
				});
			}

			if (_this.hasClass('js-logos-slider')) {
				newSlider = new Slider({
					item: _this,
					resizeHalper: true,
					swiperConfig: {
						speed: 600,
						slidesPerView: 7,
						slidesPerGroup: 1,
						spaceBetween: 40,
						resizeHalper: true,
						loop: true,
						autoplay: {
							delay: 1500
						},

						breakpoints: {

							1024: {
								slidesPerView: 5,
								slidesPerGroup: 2
							},

							640: {
								slidesPerView: 3,
								slidesPerGroup: 3,
								autoplay: {
									delay: 3000
								}
							},

							480: {
								slidesPerView: 2,
								slidesPerGroup: 1
							}
						}
					}
				});
			}

			sliders.push(newSlider);
		});
	});
	}

	$(function () {
		$('.js-dropdown').click(function (e) {
			$(this).toggleClass('is-open');
			$('.left-block__menu-list').addClass('scroll-hide');

			$('.drop-menu').stop().slideToggle(300, function () {
				$('.left-block__menu-list').removeClass('scroll-hide');
			});
		});
	});

	$(function () {
		$('.fixed-button').click(function () {
			$('.fixed-menu').toggleClass('is-open');
		});
	});

	var toTop = require('./_modules/to-top-anchor.js');
	toTop({
		active_class: 'is-active',
		from_top: 800,
		item_class: '#js-to-top',
		statik: false,
		speed: 600
	});

	$(window).scroll(function () {
		var height = $(window).scrollTop();

		if (height > 50) {
			$('.js-on-scroll').addClass('on-scroll');
		} else {
			$('.js-on-scroll').removeClass('on-scroll');
		}

		if (height > 650) {
			$('.scroll-up').addClass('on-scroll');
			$('.js-scroll-line').addClass('on-scroll');
		} else {
			$('.scroll-up').removeClass('on-scroll');
			$('.js-scroll-line').removeClass('on-scroll');
		}
	});

	scrollButton();

	$(window).resize(function () {
		scrollButton();
	});

	function scrollButton () {
		var width = $(window).width();
		if (width < 1025) {
			$('.js-scroll-btn').addClass('button button--bg scroll--md');
		} else {
			$('.js-scroll-btn').removeClass('button button--bg scroll--md');
		}
	}

	(function () {
		const $tabsWrapper = $('.js-tabs-block');

		$tabsWrapper.each(function () {
			let $el = $(this);

			let $arrowLeft = $el.find('.js-button-tab--prev');
			let $arrowRight = $el.find('.js-button-tab--next');
			let $tabContent = $el.find('.js-tab-content');
			let $tabButton = $el.find('.js-tab-button');

			let totalSize = $tabContent.length;
			let activeTab = $el.find('.js-tab-content.is-active').attr('data-wstabs-block');

			let activeButton = $el.find('.js-tab-button.is-active');

			let activeContent = $el.find('.js-tab-content.is-active');

			$arrowRight.on('click', function () {
				activeContent.removeClass('is-active');
				activeButton.removeClass('is-active');

				if (activeTab >= totalSize) {
					activeTab = 1;
				} else {
					activeTab = Number(activeTab) + 1;
				}

				activeContent = $tabContent.eq(activeTab - 1);
				activeButton = $tabButton.eq(activeTab - 1);
				activeContent.addClass('is-active');
				activeButton.addClass('is-active');
			});

			$arrowLeft.on('click', function () {
				activeContent.removeClass('is-active');
				activeButton.removeClass('is-active');

				if (activeTab <= 0) {
					activeTab = totalSize;
				} else {
					activeTab = Number(activeTab) - 1;
				}

				activeContent = $tabContent.eq(activeTab - 1);
				activeButton = $tabButton.eq(activeTab - 1);
				activeContent.addClass('is-active');
				activeButton.addClass('is-active');
			});
		});
	})();

	if ($('.js-brand-item-name').length) {
		$('.js-brand-item-name').each(function (index) {
			const $this = $(this);
			let text = $this.text().split(' ');
			let $elements = [];
			let parentWidth = $this.width();

			function compareElements (el1, el2) {
				if (!el1 || !el2) {
					return false;
				}

				let elsWidth = el1.outerWidth(true) + el2.outerWidth(true);

				if (parentWidth >= elsWidth) {
					return true;
				} else {
					return false;
				}
			}

			for (let i = 0; i <= text.length - 1; i++) {
				let el = document.createElement('span');

				$(el).text(text[i] + ' ');

				$elements.push($(el));
			}

			$this.html('');

			for (let i = 0; i <= $elements.length - 1; i++) {
				$this.append($elements[i]);
			}

			for (let i = 0; i < $elements.length; i++) {
				let el = $elements[i];
				let nextEl = $elements[i + 1];

				if (compareElements(el, nextEl)) {
					el.text(el.text() + nextEl.text());
					nextEl.remove();
					i += 2;
				}
			}
		});
	}

	if ($('.js-custom-scroll-block').length || $('.js-select').length) {
		require.ensure([], (require) => {
			require('_vendors/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js');
			require('_vendors/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css');

			if ($('.js-select').length) {
				$('.js-select').on('select2:open', function (e) {
					console.log($('.select2-results__options'));
					$('.select2-results').mCustomScrollbar();
				});
			}
			if ($('.js-custom-scroll-block').length) {
				$('.js-custom-scroll-block').mCustomScrollbar();
			}
		}, 'customScrollMailhu');
	}

	$('.js-previewer-trigger').on('click', function () {
		$('#js-selo-previewer-list').toggleClass('is-active');
	});

	if (!window.Modernizr.ios) {
		var buttons = document.getElementsByClassName('hover-wonderful');
		Array.prototype.forEach.call(buttons, function (b) {
			b.addEventListener('mouseenter', createRipple);
		});
	}

	function createRipple (e) {
		var circle = document.createElement('div');
		this.appendChild(circle);

		var d = Math.max(this.clientWidth, this.clientHeight);

		circle.style.width = circle.style.height = d + 'px';

		var rect = this.getBoundingClientRect();
		circle.style.left = e.clientX - rect.left - d / 2 + 'px';
		circle.style.top = e.clientY - rect.top - d / 2 + 'px';

		circle.classList.add('ripple-2');
	}

	function formNewContent (data) {
		let content =
			'<div class="google-map-infowindow" data-google-map-infowindow="">' +
				'<div class="wysiwyg wysiwyg--map">' +
					'<p>' +
						data.name +
						tels() +
						mails() +
					'</p>' +
				'</div>' +
			'</div>';

		function tels () {
			let el = [];

			data.tels.each(function () {
				el.push('<a class="map-info-item map-phone" href="tel:' + $(this).html() + '">' + $(this).html() + '</a>');
			});

			el = el.join('');

			return el;
		}

		function mails () {
			let el = [];

			data.mails.each(function () {
				if ($(this).html().length) {
					el.push('<a class="map-info-item map-adress" href="mailto:' + $(this).html() + '">' + $(this).html() + '</a>');
				}
			});

			el = el.join('');

			if (el.length) {
				return el;
			} else {
				return '';
			}
		}

		return content;
	}

	function scrollToMap () {
		$('.js-adress').on('click', function () {
			let adress = $(this).attr('data-adress').split(' ').join('+');
			let el = $(this).closest('.chooses-brand');

			let newContent = {
				tels: el.find('.js-phone'),
				mails: el.find('.js-mail '),
				name: el.find('.chooses-brand__name').html()
			};

			$('html, body').stop().animate({
				scrollTop: $('#map-contact').offset().top
			}, 500);

			adress = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + adress + '&key=AIzaSyDB4l09H9nPZkvdnWvDOC-s7vIB_ehxJmw';

			console.log(adress);

			$.ajax({
				url: adress,
				type: 'post',
				dataType: 'json',
				cache: false,
				success: function (response) {
					let lat = response.results[0].geometry.location.lat;
					let lng = response.results[0].geometry.location.lng;

					window.markerResize();

					window.map.setCenter({
						lat: lat,
						lng: lng + window.markerResize()
					});

					window.marker.setPosition({
						lat: lat,
						lng: lng
					});

					window.mapcenterhalper = {
						lat: lat,
						lng: lng + window.markerResize()
					};

					let newc = formNewContent(newContent);

					window.iwglobalquostil = newc;

					window.infoWindow.setContent(newc);

					// window.infoWindow.setPosition({
					// 	lat: lat,
					// 	lng: lng
					// });
				}
			});
		});
	}

	scrollToMap();

	if (IS_PRODUCTION) {
		import('#/_modules/wezom-log');
	}
});
